.full {
    padding: 0.5em 2em 0 2em;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }
  
  .heder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }

  .animated_image {
    animation: bounce 2s ease-in-out;
    max-width: 11em;
    height: auto;
  }
  
  .bottomHR {
    border: none;
    height: 1.5px;
    background-color: gray;
  }
  .backIco {
    height: 1.3em;
    width: 1.3em;
  }
  
  .backbtn {
    font-family: "Poppins", sans-serif;
    font-size: medium;
    border-radius: 9px;
    padding: 5px;
    border: 1px solid black;
    cursor: pointer;
    color: black;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

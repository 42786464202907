.card {
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    width: 250px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
  }
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .image {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .content {
    padding: 15px;
  }
  
  .title {
    font-size: 1.1em;
    margin: 0;
    font-weight: bold;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow:ellipsis; 
  }
  
  .author {
    color: #555;
    margin: 5px 0;
  }
  
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .price {
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
    text-decoration: none;
  }
  @media (max-width: 768px) {
    .card {
      max-width: 200px;
    }
  
    .image {
      height: 120px;
    }
  
    .title {
      font-size: 1em;
    }
  
    .price {
      font-size: 1em;
    }
  }
  
  @media (max-width: 480px) {
    .card {
      max-width: 150px;
      height:12em;
    }
  
    .image {
      height: 100px;
    }
  
    .title {
      font-size: 0.98em;
    }
  
    .price {
      font-size: 0.93em;
    }
  }
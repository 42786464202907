.full {
    padding: 0.5em 2em 0 2em;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }
  
  .heder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
  
  .animated_image {
    animation: bounce 2s ease-in-out;
    max-width: 11em;
    height: auto;
  }
  
  .bottomHR {
    border: none;
    height: 1.5px;
    background-color: gray;
  }
  
  .body {
padding:2em;
display: flex;
justify-content: center;

  }

  .formBody{
    width: fit-content;
    border-radius: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25); 
  }
  .formBodyLeftMain{
    padding: 3em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .formBodyLeft{
    padding: 3em;
    display: flex;
    flex-direction: row;
    width: 130%;

    gap: 0.5em;
    align-items: center;
    justify-content: center;
  }
  .formBodyLeftC1{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    width: 80%;


  }
  .formBodyLeftC2{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    width: 80%;

  }

  .termsLabele{
    font-size: smaller;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .labelDiv{

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 110%;
    margin-top: -2em;
  }

  .ButtonCreate{
    padding: 1em;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    border: none;
    background-color: goldenrod;
    border-radius: 25px;
    margin-bottom: 1em;
    color: black;
  }

  .ButtonCreate:hover{
    background-color: gold;
  }

  .backIco {
    height: 1.3em;
    width: 1.3em;
  }
  
  .backbtn {
    font-family: "Poppins", sans-serif;
    font-size: medium;
    border-radius: 9px;
    padding: 5px;
    border: 1px solid black;
    cursor: pointer;
    color: black;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  .GotoLogin{
    color: goldenrod;
    cursor: pointer;


  }

  .otpSection{
    display: flex;
    flex-direction: column;
    gap: 3em;
    padding: 4em;
  }
  .otpButtons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

  }

  .ResendOtp{
    font-family: "Poppins", sans-serif;
    font-size: small;
    margin-top: -1em;
    cursor: pointer;
    text-decoration: underline;
  }

  @media (max-width: 769px) {
   
    .body {
      padding:2em 0 0 0;
      display: flex;
      justify-content: center;
      
      }
      .formBodyLeft{
        padding: 1em;
        display: flex;
        flex-direction: row;
        width: 110%;
        align-items: center;
        justify-content: center;
      }
      
      .labelDiv{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 0;
    
      }
    
  }
  @media (max-width: 580px) {     
    .otpSection{

      padding: 3em;
    }
}
  @media (max-width: 555px) {     
      .labelDiv{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 0;
    
      }
      .otpInput{
        width: 70%;
      }
      .otpSection{

        padding: 2em;
      }
  }
  
  @media (max-width: 495px) {    
    .body {
      padding:1em 0 0 0;
      display: flex;
      justify-content: center;
      
      }
      .formBodyLeft{
        padding: 0.7em;
        display: flex;
        flex-direction: row;
        width: 130%;
        align-items: center;
        justify-content: center;
      } 
    .labelDiv{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 115%;
      margin-top: 0;
  
    }
    .otpInput{
      width: 50%;
    }
    .otpSection{

      padding: 1.5em;
    }
} 

@media (max-width: 418px) {     
  .labelDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 0;

  }
  .otpInput{
    width: 50%;
  }
  .otpSection{
   
    padding: 1em;
  }
}
 @media (max-width: 410px) {    
  .body {
    padding:1em 0 0 0;
    display: flex;
    justify-content: center;
    
    }
    .formBodyLeft{
      padding: 0.7em;
      display: flex;
      flex-direction: row;
      width: 140%;
      align-items: center;
      justify-content: center;
    } 
  .labelDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 120%!important;
    margin-top: 0;

  }

}

.mainC {
    padding: 0.5em 2em 0 2em;
    display: flex;
    flex-direction: column;
    gap: 2em;
  }
  .mainB {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  .heder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
  
  .animated_image {
    animation: bounce 2s ease-in-out;
    max-width: 11em;
    height: auto;
  }
  
  .bottomHR {
    border: none;
    height: 1.5px;
    background-color: gray;
  }
  
  .backIco {
    height: 1.3em;
    width: 1.3em;
  }
  .deleteButton {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(255, 255, 255, 0.3);
    border: none;
    border-radius: 25px;
    padding: 2px;
    cursor: pointer;
    z-index: 10;
  }
  
  .deleteButton i {
    width: 18px;
    height: 18px;
    color: #ff4d4d;
  }

  .backbtn {
    font-family: "Poppins", sans-serif;
    font-size: medium;
    border-radius: 9px;
    padding: 5px;
    border: 1px solid black;
    cursor: pointer;
    color: black;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .imageWarning{
    font-size: smaller;
    font-family: "Poppins", sans-serif;
    color: gray;
  }
  
  .body{

    height: 100%;
    width: 100%;
 
  }

  .Btop{
    display: flex;
    flex-direction: column;
    gap: 0.3em;
  }

  .tableDiv {
    min-height: 7em;
    max-height: 10em;
    overflow-y: auto;
    background-color: white; 
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 
                0px 2px 4px rgba(0, 0, 0, 0.06); 
    border-radius: 8px; 
    border: 1px solid rgba(0, 0, 0, 0.1); 
    padding: 0.5em; 
    transition: transform 0.2s ease, box-shadow 0.2s ease; 
  }

  .tableLabel{
    font-family: "Poppins", sans-serif;
    font-size: larger;
    color: black;
    margin-left: 0.5em;
  }
  .textboxes12{
    display: none;
  }
  .bottom{
    display: flex;
    flex-direction: row;
    gap: 1em;
    margin-top: -1.5em;
   
  }

  .bottom2{
    display: flex;
    flex-direction: column;
 
  }

  .mapContainer{
    border-radius: 10px;
    width: 30%;
    height: 20em;
  }

  .bottomtexts{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1%;
  }

  .textboxes,.textboxes1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5%;
    height: 100%;
  }



  .RadioButtons{
    display: flex;
    flex-direction: row;
    width: 100%;
  }

    .verticalline {
     
        width: 1px;          
        height: 19em;       
        background-color: black; 
        border: none;        
      
    }
  

    
    .container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
      padding: 20px;
      width: 30%;
    }
    
    .box {
      position: relative;
      width: 100%;
      padding-top: 100%; 
      border: 2px dashed #ccc;
      border-radius: 8px;
      transition: transform 0.3s, box-shadow 0.3s;
      cursor: pointer;
    }
    
    .box:hover {
      transform: scale(1.05);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }
    
    .withImage {
      border: none;
      background-size: cover;
      background-position: center;
    }
    
    .label {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.8);
      transition: background-color 0.2s;
    }
    
    .label:hover {
      background-color: rgba(255, 255, 255, 0.6);
    }
    
    .plus {
      font-size: 3rem;
      color: goldenrod;
    }
    
    .input {
      display: none; 
    }
    
 .SubmitButton{
  color: black;
  border: goldenrod solid 2px;
  background-color: transparent;
  font-size: large;
  padding: 0.1em;
  font-weight: 560;
  font-family: "Poppins", sans-serif;
  transition: all 0.3s ease;
 }

 .SubmitButton:hover {
  color: black;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2); 
}

.selectBoxes{
  display: flex;
  flex-direction: row;
  justify-content:center;
  align-items:center;
  gap: 0.4em;
  width: 100%;
}

.textboxesgroup{
  background-color: aqua;
  width: 50%;

  display: flex;
  flex-direction: row;
}
@media (max-width: 1250px) {
  
  .bottom{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    justify-content: center;
    align-items: center;
  }
  .verticalline {
     
    width: 19em;           
    height: 1px; 
    background-color: black; 
    border: none;
  
}
.bottom2{
  display: flex;
  flex-direction: column;


gap: 2em;

}
.bottomtexts{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  gap: 1%;
}
.textboxes, .textboxes1{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  height: 100%;
}
.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  padding: 10px;
  width: 37%;
}
.mapContainer{
  border-radius: 10px;
  width: 50%;
  height: 20em;
}
.SubmitButton{
  margin-bottom: 3em;
}
}


@media (max-width: 1044px) {
  .container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    padding: 10px;
    width: 35%;
  }
  .mapContainer{
    border-radius: 10px;
    width: 45%;
    height: 20em;
  }

}

@media (max-width: 950px) {
  .container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    padding: 9px;
    width: 50%;
  }
  .mapContainer{
    border-radius: 10px;
    width: 50%;
    height: 20em;
  }
  .SubmitButton{
   margin-bottom: 1em;
   }

}



@media (max-width: 731px) {
  .container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    padding: 7px;
    width: 55%;
  }
  .mapContainer{
    border-radius: 10px;
    width: 70%;
    height: 20em;
  }
  .tableDiv {
    min-height: 7em;
    max-height: 10em;
    overflow-y: auto;
    background-color: white; 
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 
                0px 2px 4px rgba(0, 0, 0, 0.06); 
    border-radius: 8px; 
    border: 1px solid rgba(0, 0, 0, 0.1); 
    width: 100%;
    transition: transform 0.2s ease, box-shadow 0.2s ease; 
  }

}

@media (max-width: 610px) {
  .container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    padding: 7px;
    width: 70%;
  }
  .textboxes, .textboxes1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    width: 40%;
  }
  .mapContainer{
    border-radius: 10px;
    width: 80%;
    height: 20em;
  }
  .tableDiv {
    min-height: 7em;
    max-height: 10em;
    overflow-y: auto;
    background-color: white; 
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 
                0px 2px 4px rgba(0, 0, 0, 0.06); 
    border-radius: 8px; 
    border: 1px solid rgba(0, 0, 0, 0.1); 
    width: 100%;
    transition: transform 0.2s ease, box-shadow 0.2s ease; 
  }
  .SubmitButton{
    margin-bottom: 2em;
  }

}

@media (max-width: 462px) {
  .container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    padding: 6px;
    width: 94%;
  }
  .bottomtexts {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    grid-template-rows: auto; /* Automatically adjust rows */
    gap: 2%;
    justify-items: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
  }
  .textboxes,.textboxes1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    width: 100%;
  }
  .textboxes12{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    width: 100%;
  }
  
  .mapContainer{
    border-radius: 10px;
    width: 100%;
    height: 20em;
  }
  .tableDiv {
    min-height: 7em;
    max-height: 10em;
    overflow-y: auto;
    background-color: white; 
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 
                0px 2px 4px rgba(0, 0, 0, 0.06); 
    border-radius: 8px; 
    border: 1px solid rgba(0, 0, 0, 0.1); 
    width: 100%;
    transition: transform 0.2s ease, box-shadow 0.2s ease; 
  }

  .DescriptionLabel,.RentalInfo{
    display: none !important;
  }

}

@media (max-width: 320px) {
  .container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    padding: 6px;
    width: 94%;
  }
  .bottomtexts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    width: 100%;
  }
  .textboxes,.textboxes1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    width: 100%;
  }
  
  
  .mapContainer{
    border-radius: 10px;
    width: 100%;
    height: 20em;
  }
  .tableDiv {
    min-height: 7em;
    max-height: 10em;
    overflow-y: auto;
    background-color: white; 
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 
                0px 2px 4px rgba(0, 0, 0, 0.06); 
    border-radius: 8px; 
    border: 1px solid rgba(0, 0, 0, 0.1); 
    width: 100%;
    transition: transform 0.2s ease, box-shadow 0.2s ease; 
  }

  

}


.mainC {
    padding: 0.5em 2em 0 2em;
    display: flex;
    flex-direction: column;
    gap: 2em;
  }
  .mainB {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  .heder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
  
  .animated_image {
    animation: bounce 2s ease-in-out;
    max-width: 11em;
    height: auto;
  }
  
  .bottomHR {
    border: none;
    height: 1.5px;
    background-color: gray;
  }
  
  .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .backbtn {
    font-family: "Poppins", sans-serif;
    font-size: medium;
    border-radius: 9px;
    padding: 5px;
    border: 1px solid black;
    cursor: pointer;
    color: black;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  .backIco{
    height: 1.3em;
    width: 1.3em;
  }


  .bodyAnnouncements{
    display: flex;
    flex-wrap: wrap;
    gap: 1.2em;
    height: 100%;
    width: 100%;


  }

.ErrorMessageNOFav{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center ;
  gap: 2em;
  position: relative; /* Use absolute positioning */
  margin-top: 5em;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%; /* Optional: ensures the content scales properly */
  height: 100%; /* Optional: same reason */
  text-align: center; /* Center-align text if applicable */

}
.ErrorMessageNOFav span{
    font-family: "Poppins", sans-serif;
    font-size: larger;
    font-weight: 300;
}

.ErrorMessageNOFav button{
  font-family: "Poppins", sans-serif;
  font-size: large;
  width: 10%;
  padding: 6px;
  border-radius: 10px;
  border: 2px solid goldenrod;
  background-color: white;
  transition: 50ms ease-in;
  color: black;
  min-width: fit-content;
}

.ErrorMessageNOFav button:hover{
  transform: scale(1.04);

  
}

.menuButtonimg1{
    height: 2em;
    width: 2em;
}

.GroupBox1{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 0.5em;

   
}

label{
  font-family: "Poppins", sans-serif;
  width: fit-content;
  color: black;

}

.SavedButton{
  color: black;

  margin-right: 0.3em;
}

.buttonRoot {
 
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 16px;
  font-family: 'Roboto', sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.75;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
}



.image {
  width: 24px; /* Adjust icon size */
  height: 24px;
}

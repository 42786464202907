.full {
    padding: 0.5em 2em 0 2em;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }
  
  .heder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
  
  .animated_image {
    animation: bounce 2s ease-in-out;
    max-width: 11em;
    height: auto;
  }
  
  .bottomHR {
    border: none;
    height: 1.5px;
    background-color: gray;
  }
  
  .body {
padding:2em;
display: flex;
justify-content: center;

  }

  .formBody{
    width: fit-content;
    border-radius: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    
  }
  .formBodyLeftMain{
    padding: 2em 3em 0 3em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }
  .formBodyLeft{
    padding: 2em 3em 2em 3em;

    display: flex;
    flex-direction: row;
    width: 130%;
    align-items: center;
    justify-content: center;
  }

  .formBodyLeftC1{
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 100%;
  }

  .GotoLogin{
    color: goldenrod;

    width:fit-content;
    cursor: pointer;
    display: flex;
    align-items: start;
    justify-content: start;

  }
  .ButtonCreate{
    padding: 1em;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    border: none;
    background-color: goldenrod;
    border-radius: 25px;
    margin-bottom: 2em;
    color: black;

  }


  .ButtonCreate:hover{
    background-color: gold;
  }

  .backIco {
    height: 1.3em;
    width: 1.3em;
  }
  
  .backbtn {
    font-family: "Poppins", sans-serif;
    font-size: medium;
    border-radius: 9px;
    padding: 5px;
    border: 1px solid black;
    cursor: pointer;
    color: black;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  
  
  @media (max-width: 480px) {
    .body {
      padding:2em 0 0 0;
      display: flex;
      justify-content: center;
      
        }
   
  }
  @media (max-width: 480px) {
    .body {
      padding:3em 0 0 0;
      display: flex;
      justify-content: center;
      
      }
      .formBodyLeft{
        padding: 1em;
        display: flex;
        flex-direction: row;
        width: 130%;
        align-items: center;
        justify-content: center;
      }
  }
  

.mainC {
    padding: 0.5em 2em 0 2em;
    display: flex;
    flex-direction: column;
    gap: 2em;
  }
  .mainB {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  .heder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
  
  .animated_image {
    animation: bounce 2s ease-in-out;
    max-width: 11em;
    height: auto;
  }
  
  .bottomHR {
    border: none;
    height: 1.5px;
    background-color: gray;
  }
  
  .backIco {
    height: 1.3em;
    width: 1.3em;
  }
  
  .backbtn {
    font-family: "Poppins", sans-serif;
    font-size: medium;
    border-radius: 9px;
    padding: 5px;
    border: 1px solid black;
    cursor: pointer;
    color: black;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  
  .body{
    display: flex;
    flex-direction: row;
 
  }

  .userdetails{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    font-family: "Poppins", sans-serif;
    gap: 1.2em; 
    margin-right: 2em;
    border: 2px solid goldenrod;
    padding: 1em;

  }
  .userdetailsAvaAndRating {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 0.5em;
  }
.userdetailsDetails{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 0.3em;
    font-family: "Poppins", sans-serif;
}

.userdetailsDetailsCauntry{
  text-decoration: none !important;

}
  .avatar{
    height:15em;
  }
  .btnGroupCall {
    border: 2px solid green; 
    background-color: #4CAF50; 
    color: white;
    width: 50%;
    min-width: fit-content !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 2%;
    padding: 5px 5px; 
    font-family: "Poppins", sans-serif;
    font-size: medium;
    border-radius: 10px; 
    cursor: pointer;
    transition: all 0.3s ease; 
    margin-top: 1em;
  }

  .verticalline {
     
    width: 1px;          
    height:25em;       
    background-color: black; 
    border: none;      
    margin: 0;  
  
}


.useranouncements {
  display: flex;
  flex-wrap: wrap;
  gap: 1.2em;
  align-items: start;
  margin-bottom: 1em;
}

.useranouncementsUserName{
  display: flex;
align-items: start;
  flex-direction: column;
  gap: 0.5em;
}

.useranouncementsUserName span{
  font-family: "Poppins", sans-serif;
  font-size: large;
}

.announcementsnumSpan{
  font-size: small;
  font-family: "Poppins", sans-serif;
  text-decoration:overline ;
  margin-top: 1em;

}



@media (max-width:890px) {
  .body{
    display: flex;
    flex-direction: column;
    gap: 2em;
  }


  .userdetails{
    display: flex;
    flex-direction: row;

  }


}

@media (max-width:840px) {

  .avatar{
    height: 12em;
  }

}


@media (max-width:560px) {
  .body{
    display: flex;
    flex-direction: column;
  }


  .userdetails{
    display: flex;
    flex-direction: column;

  }
  .avatar{
    height: 10em;
  }

}
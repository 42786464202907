.mainC {
  padding: 0.5em 2em 0 2em;
  display: flex;
  flex-direction: column;
  gap: 2em;
}
.mainB {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.heder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.animated_image {
  animation: bounce 2s ease-in-out;
  max-width: 11em;
  height: auto;
}

.bottomHR {
  border: none;
  height: 1.5px;
  background-color: gray;
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.backbtn {
  font-family: "Poppins", sans-serif;
  font-size: medium;
  border-radius: 9px;
  padding: 5px;
  border: 1px solid black;
  cursor: pointer;
  color: black;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.mebtn {
  font-family: "Poppins", sans-serif;
  font-size: medium;
  border-radius: 9px;
  padding: 5px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;

}

.locatIcon {
  height: 1.5em;
  width: 1.5em;
}

.backIco{
  height: 1.3em;
  width: 1.3em;
}

@media (max-width: 768px) {

}
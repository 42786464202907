@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

.main {
  padding: 0.5em 2em 0 2em;
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 1.5em;
}

.left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}



.bottomHR {
  border: none;
  height: 1px;
  background-color: rgb(0, 0, 0);
}

.right {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 0.4em;
}

.menuButton {
  display: none;
}

.right span:hover {
  cursor: pointer;
  color: gray;
}

.right span {
  font-family: "Poppins", sans-serif; 
  font-size: 1.1em;
}

.icons {
  display: flex;
  gap: 0.5em;
}
.menuButtonimg1 {
 cursor: pointer;
 margin-right: 0.1em;
}

.dropdownIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  margin-top: 0.5em;
}
.DropDownMenue{
  display:none;

}


@media (max-width: 600px) {
  .DropDownMenue{
    display: flex;
  }
  .top {
    flex-direction: row;
    align-items: flex-start;
  }

  .menuButton {
    display: block;
    background-color: transparent;
    border: none;
    cursor: pointer;
    justify-content: center;
    align-items: center;
  }

  .right {
    flex-direction: column;
    gap: 0.5rem;
    display: none;
    width: 100%;
  }

  .right.showMenu {
    display: flex;
  }

  .right span {
    font-size: small;
  }

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .animated_image {
    width: 7em;
    height: auto;
  }

  .menuButtonimg{
    width: 2em !important;
  }
  .menuButtonimg1 {
    width: 1.1em!important ;
   

  }
  .icons {
    display: none; 
  }

  .dropdownIcons {
    display: none; 
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
  }

  .dropdownIcons.showIcons {
    display: flex;
  }
}

@media (max-width: 768px) {
  .DropDownMenue{
    display: flex;
  }
  .top {
    flex-direction: row;
    align-items: flex-start;
  }

  .menuButton {
    display: block;
    background-color: transparent;
    border: none;
    cursor: pointer;
    justify-content: center;
    align-items: center;
  }

  .right {
    flex-direction: column;
    gap: 0.5rem;
    display: none;
    width: 100%;
  }

  .right.showMenu {
    display: flex;
  }

  .right span {
    font-size: small;
  }

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .animated_image {
    width: 7em;
    height: auto;
  }

  .menuButtonimg{
    width: 2em !important;
  }
  .menuButtonimg1 {
    width: 1.1em!important ;
  }
  .icons {
    display: none; 
  }

  .dropdownIcons {
    display: none; 
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
  }

  .dropdownIcons.showIcons {
    display: flex;
  }
}


@media (max-width: 1000px) {
  .DropDownMenue{
    display: flex;
  }
  .top {
    flex-direction: row;
    align-items: flex-start;
  }

  .menuButton {
    display: block;
    background-color: transparent;
    border: none;
    cursor: pointer;
    justify-content: center;
    align-items: center;
  }

  .right {
    flex-direction: column;
    gap: 0.5rem;
    display: none;
    width: 100%;
  }

  .right.showMenu {
    display: flex;
  }

  .right span {
    font-size: small;
  }

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .animated_image {
    width: 7em;
    height: auto;
  }

  .menuButtonimg{
    width: 2em !important;
  }
  .menuButtonimg1 {
    width: 1.1em!important ;
  }
  .icons {
    display: none; 
  }

  .dropdownIcons {
    display: none; 
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
  }

  .dropdownIcons.showIcons {
    display: flex;
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-6px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.animated_image {
  animation: bounce 1s ease-in-out;
  max-width: 10em;
  height: auto;
}

.left img {
  width: 1.8em;
  height: auto;
}

.main{
    background-color: rgba(194, 152, 45, 0.9);
    width: 100%;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.2), 0px -8px 20px rgba(0, 0, 0, 0.1);
}

.round{
    display: flex;
    flex-direction: column;
    gap:2em;
    padding: 1em ;
}

.top{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.topLeft{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;

}

.centerButtonGroup{  
      
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.7em;
    position: absolute ; 
    margin-top: 1.5em;
    left: 50%;
    transform: translate(-50%, -50%);

}

.centerButtonGroup span{
    color: rgba(64, 61, 61, 0.941);
    border: none;
    background-color: transparent;
    font-family: "Poppins", sans-serif;
    font-size: large;
  cursor: pointer;
}

.centerButtonGroup span:hover{
    color: rgba(64, 61, 61, 0.565);

}


.upButton {
    border: none;
    background-color: transparent;
    font-family: "Poppins", sans-serif;
    font-size: 1.5em;
    font-weight: 400;
    cursor: pointer;
    transition: transform 0.2s ease, color 0.2s ease;
}

.upButton:hover {
    transform: scale(0.9) translateY(-6px);
    animation: pulse 0.3s ease-in-out;

}
@keyframes pulse {
    0% {
        transform: scale(0.9) translateY(-5px);
    }
    50% {
        transform: scale(1) translateY(-8px);
    }
    100% {
        transform: scale(0.9) translateY(-5px);
    }
}


.bottom{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}


.SpanOfRights{
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

    gap: 0.7em;
    position: absolute !important; /* Overrides other positions */
    left: 50%;
    transform: translate(-50%, 60%);
}

.leftLogos{
    display: flex;
    flex-direction: row;
    gap: 0.5em;
}
.leftLogos i{
    color:aliceblue;
    font-size: 1.2em;
    cursor: pointer;
}

.leftLogos i:hover{
    color:rgba(240, 248, 255, 0.366);
 
}


.img{
    height: 3em;

}



@media screen and (max-width: 1040px) {
    
    .img{
        height: 2.5em;

    }
}

@media screen and (max-width: 950px) {
    
    .img{
        height: 2.2em;

    }
    .centerButtonGroup span{
        font-size: medium;
    }
    
    
}


@media screen and (max-width: 794px) {
    
    .img{
        height: 2.5em;

    }
    .centerButtonGroup{
        flex-direction: column;
        align-items: start;
        justify-content: start;
        position: relative;
        margin-top: 1.3em;

        
    }
    .centerButtonGroup span{
        font-size: large;
    }
    .topLeft{
        flex-direction: column;
        gap: 5em;
    }
    .top{
        align-items: start;


    }
    .bottom{
        align-items: start;
        justify-content: start;
        margin-top: -2em;
    }
    .round{    
        gap:0em;
    }
    
}
@media screen and (max-width: 597px) {
 
    
    .leftLogos{
     position: relative ; 
 

     transform: translate(0%, -60%);
    }
   
 }
 
 
 
 

@media screen and (max-width: 495px) {
 
    
   
    .topLeft{
        flex-direction: column;
        gap: 5em;
    }
    .top{
        align-items: start;


    }
    .bottom{
        align-items: start;
        justify-content: start;
        margin-top: -2.6em;
    }
.SpanOfRights{
    font-size: small;
    transform: translate(-50%, 100%);

}
.centerButtonGroup{

    margin-top: 1.5em;

    
}
}


@media screen and (max-width: 405px) {
    .SpanOfRights{
        margin-top: 0.5em;
        font-size: 0.8em;
        width: max-content;
    }
    
  
}



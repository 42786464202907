.mainC {
  padding: 0.5em 2em 0 2em;
  display: flex;
  flex-direction: column;
  gap: 2em;
}
.mainB {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.heder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.animated_image {
  animation: bounce 2s ease-in-out;
  max-width: 11em;
  height: auto;
}

.bottomHR {
  border: none;
  height: 1.5px;
  background-color: gray;
}

.backIco {
  height: 1.3em;
  width: 1.3em;
}

.backbtn {
  font-family: "Poppins", sans-serif;
  font-size: medium;
  border-radius: 9px;
  padding: 5px;
  border: 1px solid black;
  cursor: pointer;
  color: black;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}


.body{
    display: flex;
    flex-direction: row;
    gap: 2em;
    width: 100%;

}

.left{
  width:50% ;
  justify-content:start;
 
}

.mainImage{
  width: 100%;

}


.right{
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 45%;
  gap: 6%;


}

.appartmentName{
  font-family: "Poppins", sans-serif;
  font-size: x-large;
}

.userDetail{
  display: flex;
  flex-direction: row;
  text-align: center;
  gap: 4%;
  width: 40%;
  margin-top: -2%;

}

.userDetailIMG {
  height: 3em;
  width: 3em;
  border-radius: 50%; 
 
}

.userDetailName{
  font-family: "Poppins", sans-serif;
  font-size: medium;
  cursor: pointer;
}

.userDetailName:hover{
  color: gray;
}

.cityInfo{
  display: flex;
  width: fit-content;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 85%;

}

.userDetailCity{
  font-family: "Poppins", sans-serif;
  font-size: small;
  color: rgb(121, 118, 118);
  width: fit-content;

}

.descripText{
  text-align: start;
  max-height: 20%;
  width: 55%;
  overflow-y: auto;
  font-family: "Poppins", sans-serif;
  font-size: medium;

  
}

.btnGroup{
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 4%;
  width: 50%;
}

.btnGroupCall {
  border: 2px solid green; 
  background-color: #4CAF50; 
  color: white;
  width: 50%;
  min-width: fit-content !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 2%;
  padding: 5px 5px; 
  font-family: "Poppins", sans-serif;
  font-size: medium;
  border-radius: 10px; 
  cursor: pointer;
  transition: all 0.3s ease; 
}

.btnGroupBasket {
  border: none; 
  background-color: #b43131; 
  color: white;
  width: 25% ;
  min-width: fit-content !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 2%;
  padding: 7px 10px; 
  font-family: "Poppins", sans-serif;
  font-size: medium;
  border-radius: 10px; 
  cursor: pointer;
  transition: all 0.3s ease; 
}
.btnGroupBasket.active {
  border: none; 
  background-color: #b43131; 
  color: rgb(255, 255, 255);
  width: 25% ;
  min-width: fit-content !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 2%;
  padding: 7px 10px; 
  font-family: "Poppins", sans-serif;
  font-size: medium;
  border-radius: 10px; 
  cursor: pointer;
  transition: all 0.3s ease; 
}

.btnGroupCall:hover, .btnGroupBasket:hover, .btnGroupFavorite:hover {
  transform: scale(1.04);
}


.appartmentprice{
  font-family: "Poppins", sans-serif;
  font-size: 3em;
  font-weight: 400;
  width: fit-content;
  text-decoration: none;
}

.appartmentpriceandvalute{
  display: flex;
  flex-direction: row;
  gap: 2%;
  text-align: baseline;
  justify-content: baseline;
  align-items: baseline;
  width: fit-content;

}

.appartmentpricevalute{
  font-family: "Poppins", sans-serif;
  font-size: medium;
  font-weight: 350;

}

.footer {
  margin-bottom: 3em;
  margin-top: 2em;
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  grid-template-rows: repeat(4, auto); 
  gap: 20px; 
  align-items: start; 
  padding: 20px; 
  background-color: #f7f7f7; 
  border-radius: 15px; 
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
}

.footer span {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding: 12px 18px;
  background-color: #ffffff;
  border-radius: 10px;
  font-size: 16px; 
  color: #333333;
  font-weight: 400; 
  transition: all 0.3s ease; 
  width: 100%;
  min-width:fit-content;
  overflow-x: auto;
}

.footer span i {
  margin-right: 10px; 
  font-size: 20px; 
  color: #6c757d;
}


.footer span .label {
  font-weight: 550; 
  color: #DAA520; 
  margin-right: 8px; 
  font-family: "Poppins", sans-serif;
}

.footer span .data {
  color: #555555; 
  font-weight: 400;
  margin-left: 4px;
  opacity: 0.9; 
  font-family: "Poppins", sans-serif;
}
.streetspan{
cursor: pointer;
text-decoration: underline dotted goldenrod ;
}
.streetspan:hover {
  color: #007bff; /* Optional: Add hover color */
}

.MapContainer {


}

@media (max-width:1645px){
  .userDetailName{
    font-family: "Poppins", sans-serif;
    font-size: medium!important;
    font-weight: 450;
    width: max-content;
  }
  .userDetailCity{
    font-family: "Poppins", sans-serif;
    font-size: smaller!important;
    font-weight: 450;
    width: max-content;

  }
  .cityInfo{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 100%;
  
  }
  
  .userDetailCity{
    /* font-family: "Poppins", sans-serif; */
    font-size: smaller!important;
    color: rgb(121, 118, 118);
  
  }
}
@media (max-width: 1274px) {
  .footer {
    margin-bottom: 3em;
  }
  .footer span {
    font-family: "Poppins", sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding: 12px 15px;
    background-color: #ffffff;
    border-radius: 10px;
    font-size: 16px; 
    color: #333333;
    font-weight: 400; 
    transition: all 0.3s ease; 
    width: 100%;
  }
}
@media (max-width: 1219px) {
  .footer {
    margin-bottom: 3em;
  }
  .footer span {
    font-family: "Poppins", sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding: 12px 14px;
    background-color: #ffffff;
    border-radius: 10px;
    font-size: 16px; 
    color: #333333;
    font-weight: 400; 
    transition: all 0.3s ease; 
    width: 100%;
  }
}
@media (max-width: 1202px) {
  .footer {
    margin-bottom: 3em;
  }
  .footer span {
    font-family: "Poppins", sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding: 12px 9px;
    background-color: #ffffff;
    border-radius: 10px;
    font-size: 16px; 
    color: #333333;
    font-weight: 400; 
    transition: all 0.3s ease; 
    width: 100%;
  }
}
@media (max-width: 1050px) {
  .body {
    flex-direction: column; 
    gap: 2em;
  }

  .left, .right {
    width: 100%; 
    height: 100%;
  }

  .right{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    gap: 2em;
  }

  .userDetail{
    display: flex;
    flex-direction: row;
    text-align: center;
    gap: 4%;
    margin-top: -1.3em;

  }
  
  .mainImage {
    width: 100%; 
   
  }

  .btnGroup {
  
    flex-direction: row; 
    gap: 1em; 
  }

  .btnGroupCall, .btnGroupBasket, .btnGroupFavorite {

    text-align: center; 
  }

  .descripText {
    width: 55%; 
    max-height: 6em;
    overflow-y: auto;
  }

  .appartmentprice {
    font-size: 3em; 
  }

  .footer {
   
    padding: 2em;
  }
  .footer span {
    font-family: "Poppins", sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding: 12px 7px;
    background-color: #ffffff;
    border-radius: 10px;
    font-size: 14px; 
    color: #333333;
    font-weight: 400; 
    transition: all 0.3s ease; 
    width: 100%;
  }
}

@media (max-width: 978px) {
  .footer {
    grid-template-columns: repeat(2, 1fr) !important; /* Change to 4 columns */
    grid-template-rows: repeat(4, auto); /* Change to 3 rows */
  }
}
@media (max-width: 712px) {
  .footer span {
    font-family: "Poppins", sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding: 10px 5px;
    background-color: #ffffff;
    border-radius: 10px;
    font-size: 12px; 
    color: #333333;
    font-weight: 400; 
    transition: all 0.3s ease; 
    width: 100%;
  }
}

@media (max-width: 631px) {
  .footer span {
    font-family: "Poppins", sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding: 10px 5px;
    background-color: #ffffff;
    border-radius: 10px;
    font-size: 11px; 
    color: #333333;
    font-weight: 400; 
    transition: all 0.3s ease; 
    width: 100%;
  }
}

@media (max-width: 600px) {
  .mainC {
    padding: 0.5em 1em 0 1em; 
  }

  .appartmentprice {
    font-size: 2.5em;
  }

  .appartmentpriceandvalute {
    gap: 1%; 
  }




  .animated_image {
    max-width: 8em; 
  }
  .userDetail{
    display: flex;
    flex-direction: row;
    text-align: center;
    gap: 5%;
    width: 60%;
    margin-top: -1.3em;

  }
  .footer span {
    font-family: "Poppins", sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding: 10px 4px;
    background-color: #ffffff;
    border-radius: 10px;
    font-size: 10px; 
    color: #333333;
    font-weight: 400; 
    transition: all 0.3s ease; 
    width: 100%;
  }
}

@media (max-width: 510px) {
  .footer {
    grid-template-columns: repeat(1, 1fr) !important; /* Change to 4 columns */
    grid-template-rows: repeat(5, auto); /* Change to 3 rows */
  }
  .footer span {
    font-family: "Poppins", sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding: 10px 8px;
    background-color: #ffffff;
    border-radius: 10px;
    font-size: 12px; 
    color: #333333;
    font-weight: 400; 
    transition: all 0.3s ease; 
    width: 100%;
  }
}